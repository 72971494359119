// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import BSAShortHeader from '../../components/BestSchoolsAwards/Header/ShortHeader'
import React from 'react'
import { Helmet } from 'react-helmet'
import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const BSATerms = () => {
  return (
    <>
      <Helmet>
        <title>
          T &amp; Cs | Muddy Stilettos&apos; Best Schools Awards | Muddy
          Stilettos
        </title>
      </Helmet>
      <BSAShortHeader />
      <BSAContent>
        <div
          className={ContentStyles.NarrowContent}
          style={{ textAlign: 'center' }}
        >
          <h1>Terms &amp; Conditions</h1>
          <p>
            <ol>
              <li>
                Applications must relate to activity primarily undertaken in the
                2022/23 & 2023/24 academic years.
              </li>
              <li>
                Entrants warrant the validity of the claims made and the facts
                provided in their entrance form.
              </li>
              <li>
                Entrants confirm that they are an independent fee-paying school
                or fee-paying boarding school in the United Kingdom or the
                Channel Islands.
              </li>
              <li>
                In providing information and images in support of their
                application, entrants agree that the publishers of the Muddy
                Stilettos portfolio and their Licensees may use the materials
                provided to promote the awards and to feature the stories on
                their websites, social platforms, newsletters and press
                releases; thereby they warrant that all the necessary
                permissions have been obtained. Schools may withdraw their
                permission for Muddy Stilettos to use awards nomination
                information by emailing{' '}
                <a
                  target="_BLANK"
                  href="mailto:hq@muddystilettos.co.uk"
                  rel="noreferrer"
                >
                  hq@muddystilettos.co.uk
                </a>
                .
              </li>
              <li>
                Entrants accept the decision of the Judging Panel as final and
                binding.
              </li>
              <li>
                Submissions will open in early January 2024. Closing date has
                now been extended to 3pm, 23 April 2024. (GMT)
              </li>
              <li>Schools may enter more than one award category.</li>
              <li>
                Each entry must be submitted using a separate Entry form and
                should be tailored to address the criteria set out for the
                appropriate category. Submissions not using the specified Best
                Schools Award Entry form will be disqualified.
              </li>
              <li>
                Schools must confirm whether they want each entry form to be
                judged as a whole school, senior school or prep school when
                prompted
              </li>
              <li>
                Muddy Stilettos reserves the right to re-allocate entries into
                relevant categories if deemed appropriate; you will be notified
                of any changes.
              </li>
              <li>
                Muddy Stilettos reserves the right to cancel or merge categories
                if there are insufficient entries; you will be notified of any
                changes.
              </li>
              <li>
                Muddy Stilettos reserves the right to alter dates above as
                necessary.
              </li>
              <li>
                Please fill in all sections of the entry form and supply your
                contact details in full. Save as PDF (max size is 6 MB).
              </li>
              <li>
                You must upload a school logo to your entry form. Additional
                images to supplement your nomination are encouraged but must be
                supplied as a Jpeg/PNG file or a high-resolution image (up to 6
                MB). Video may also be used to supplement your nomination –
                please link to an online hosting platform such as YouTube or
                Vimeo entry forms not filled in correctly or which are
                incomplete may be disqualified.
              </li>
              <li>
                If more than one image is uploaded Muddy Stilettos reserves the
                right to choose the photo used for marketing purposes.
              </li>
              <li>
                Any entry forms not filled in correctly or which are incomplete
                may be disqualified at the discretion of the Chair of the
                judges.
              </li>
              <li>
                All entrants will be notified that their entry has been
                correctly submitted. Please contact us if you do not receive
                notification of entry.
              </li>
              <li>
                Entries received after 3pm, 23 April 2024 will not be entered.
              </li>
              <li>
                The school name on the entry form will be how Muddy Stilettos
                will refer to it across the website and via promotion.
              </li>
              <li>
                For further information please read our ‘
                <a href="/best-schools-awards/faqs/">
                  Frequently Asked Questions
                </a>
                ’.{' '}
              </li>
            </ol>
          </p>
        </div>
      </BSAContent>
      <BSAFooter />
    </>
  )
}

export default BSATerms
